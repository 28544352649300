import React from "react";
import "./Resume.css";
import ListItems from "../ListItems.js";

const competencies = [
  "Clear Communication Skills",
  "Leadership Skills",
  "High Proficiency in C++",
  "Remote Development Teams",
  "Salesforce Development Processes",
  "Algorithm Implementation/Analysis",
  "OOP/Functional/Declarative Programming",
  "Mobile-First Web Development/Design",
  "Version Control & Git",
  "Managing Customer Expectations",
  "AGILE/SCRUM",
  "Web Components",
];

const languages = [
  "C",
  "C++",
  "Java",
  "Python",
  "Lua",
  "Prolog",
  "Erlang",
  "Kotlin",
  "JS",
  "HTML",
  "CSS",
  "GNU/Linux/Unix",
];

const salesforceExpList = [
  "Delivered cloud-connected digital experiences in small & large AGILE product development teams",
  "Integrated Salesforce technologies with external technologies using REST API with MuleSoft",
  "Developed Software Solutions using declarative methods as well as custom code",
  "Customized experiences (websites) using server-side APEX & client-side Lightning Web Components",
  "Initiated communication between customers, management, business owners, and cross-team technicians",
  "Contributed to Project Collaboration & Planning with AGILE Ceremonies",
  "Built custom code solutions using APEX (Similar to Java), JS, HTML, CSS, XML",
  "Optimized Dev-Ops processes with Git Version Control and CI/CD tools",
  "Designed Architectural Elements for Integrated Full-Stack Web Solutions",
  "Wrote code samples, exam questions, and solution code for Trailhead developer training courses",
  "Built headless online B2B storefronts from scratch, primarily focusing on frontend and integration",
];

const senecioExpList = [
  "Worked in AGILE sprint cycles alongside remote and hybrid dev teams for multiple projects",
  "Developed code remotely for multiple self-managed projects with small teams of like-minded developers",
  "Created an android camera app from scratch with a pre-trained machine-learning model (TensorFlow) implemented for increased functionality",
  "Partially completed the front-end development of a website for a client-facing project transitioning away from a drag-and-drop template site",
  "Contributed toward the development of Locate2Learn, an interactive mobile game that allows educators to administer digital quizzes/assessments to engage kinesthetic learners through play",
];

function Resume() {
  return (
    <div className="resume">
      <div className="resume-top-left">
        <div className="resume-top">
          <div className="resume-title">Ryan Zimcosky</div>
          <div className="resume-subtitle">Software Engineer</div>
          <div className="resume-subtitle">Westlake, Ohio, USA</div>
          <div className="resume-subtitle">
            <div>
              <code>Phone ☎️: </code>
              <a href="tel:4403461766"> +1 (440) 346-1766</a>
            </div>
            <div>
              <code>Email 📧: </code>
              <a href="mailto:zryan@bgsu.edu"> zryan@bgsu.edu </a>
            </div>
          </div>
          <div className="resume-subtitle">
            <div>
              <code>LinkedIn: </code>
              <a href="https://www.linkedin.com/in/ryan-zimcosky">
                linkedin.com/in/ryan-zimcosky
              </a>
            </div>
            <div>
              <code>Website: </code>
              <a href="https://www.ryanzimcosky.com/">ryanzimcosky.com</a>
            </div>
          </div>
        </div>
        <div className="resume-bottom">
          <div className="resume-title-2">Skills</div>
          <div className="resume-subtitle-2">Programming Languages</div>
          <div className="resume-details">
            <ListItems items={languages} />
          </div>
          <div className="resume-subtitle-2"> Relevant Competencies </div>
          <div className="resume-details-2">
            <ListItems items={competencies} />
          </div>
        </div>
      </div>

      <div className="resume-top-right">
        <div className="resume-title-3">Work Experience</div>
        <div className="work-item">
          <div>
            <div className="work-company">Salesforce</div>
            <div className="work-title">Software Developer</div>
          </div>
          <div>
            <div className="work-location">Remote</div>
            <div className="work-dates">3 years</div>
          </div>
          <div className="work-exp">
            <ListItems items={salesforceExpList} />
          </div>
        </div>
        <div className="work-item">
          <div>
            <div className="work-company">Senecio Corporation</div>
            <div className="work-title">Software Developer</div>
          </div>
          <div>
            <div className="work-location">Bowling Green, Ohio</div>
            <div className="work-dates">1 Year</div>
          </div>
          <div className="work-exp">
            <ListItems items={senecioExpList} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resume;
