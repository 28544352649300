import React from "react";

const Snake = props => {
  const headIndex = props.snakeDots.length - 1;

  const cornerStyling = ({dot, forwardDot, aftDot, isHead, isTail}) => {
    let bordersCovered = {
      head: false,
      top: false,
      bottom: false,
      left: false,
      right: false,
    }

    if (aftDot !== undefined && aftDot !== null) {
      bordersCovered.top = (dot[1] > aftDot[1]);
      bordersCovered.bottom = (dot[1] < aftDot[1]);
      bordersCovered.left = (dot[0] > aftDot[0]);
      bordersCovered.right = (dot[0] < aftDot[0]);
    } 
    if (forwardDot !== undefined && forwardDot !== null) {
      bordersCovered.top = bordersCovered.top || (dot[1] > forwardDot[1]);
      bordersCovered.bottom = bordersCovered.bottom || (dot[1] < forwardDot[1]);
      bordersCovered.left = bordersCovered.left || (dot[0] > forwardDot[0]);
      bordersCovered.right = bordersCovered.right || (dot[0] < forwardDot[0]);
    }

    if (isHead) {
      switch (props.direction) {
        case 'LEFT' : 
          if (bordersCovered.right) { return ' top-left-curve bottom-left-curve'; } 
          else if (bordersCovered.top) { return ' bottom-left-curve bottom-right-curve'; } 
          else { return ' top-left-curve top-right-curve'; }
        case 'RIGHT' : 
          if (bordersCovered.left) { return ' top-right-curve bottom-right-curve'; }
          else if (bordersCovered.top) { return ' bottom-left-curve bottom-right-curve'; } 
          else { return ' top-left-curve top-right-curve'; }
        case 'UP' : 
          if (bordersCovered.bottom) {return ' top-left-curve top-right-curve';}
          else if (bordersCovered.left) { return ' bottom-right-curve top-right-curve'; }
          else { return ' bottom-left-curve top-left-curve'; }
        case 'DOWN' : 
          if (bordersCovered.top) { return ' bottom-left-curve bottom-right-curve'; }
          else if (bordersCovered.left) { return ' bottom-right-curve top-right-curve'; }
          else { return ' bottom-left-curve top-left-curve'; }
        default : break;
      }
    }


    if (bordersCovered.top && bordersCovered.left) { return ' bottom-right-curve'; }
    else if (bordersCovered.top && bordersCovered.right) { return ' bottom-left-curve'; }
    else if (bordersCovered.bottom && bordersCovered.left) { return ' top-right-curve'; }
    else if (bordersCovered.bottom && bordersCovered.right) { return ' top-left-curve'; }
    else return '';
  }

  return (
    <div>
      {props.snakeDots.map((dot, i) => {
        let className = 'snake';
        let isHead = (i === headIndex);
        let isTail = (i === 0);
        let aftDot = (isTail) ? null : props.snakeDots[i - 1];
        let forwardDot = (isHead) ? null : props.snakeDots[i + 1];
        className += cornerStyling({dot, forwardDot, aftDot, isHead, isTail});

        ((i + headIndex) % 4 === 0) ? (className += ' ring-color') : (className += ' base-color');
        const style = { left: `${dot[0]}%`, top: `${dot[1]}%` };

        return <div className={className} key={i} style={style} />;
      })}
    </div>
  );
};
export default Snake;
