import { useState } from "react";
import logoBoat from '../../images/LogoBoat.min.svg';
import logoBrackets from '../../images/LogoBrackets.min.svg';
import Resume from '../Resume/Resume.js';
import SnakeGame from '../SnakeGame/SnakeGame.js';
import PolyRhythm from '../../~experimental/PolyRythm/PolyRhythm.js';
import './App.css';
import '../../styles/Animations.css';

function App() {
  const [selectedTab, setSelectedTab] = useState('none');  
  const isSelected = (tab) => {
    return selectedTab === tab ? 'button selected' : 'button';
  }

  const scrollToContent = () => {
    let rect = document.getElementById('content').getBoundingClientRect();
    console.log(rect);
    setTimeout(function() {
      window.scrollTo({
        top: rect.y,
        left: 0,
        behavior: "smooth",
      });
    }, 100);
  }

  return (
    <div className="app-wrapper">
      <header className="App-header">
        <h1 className='header-text'> Ryan Zimcosky's <br/> Website </h1>
        <div className="wrap-logo">
          <div className="logo-layer colorize">
            <div className="logo-layer bounce">
              <img src={logoBoat} className="App-logo logo-layer rock" alt=''/>
            </div>
            
          </div>
          <img src={logoBrackets} className="App-logo slight-bounce" alt=''/>
        </div>

        <h3 className='header-3-Text'>Made with ReactJS</h3>
      </header>

      <div className='App-body'>
        <div className='button-grp'>
          <button 
            className={isSelected('resume')} 
            onClick={() => {
              selectedTab==='resume' ? setSelectedTab('none') : setSelectedTab('resume');
              scrollToContent();
            }}
          >Resume
          </button>
          <button 
            className={isSelected('projects')} 
            onClick={() => {
              selectedTab==='projects' ? setSelectedTab('none') : setSelectedTab('projects');
              scrollToContent();
            }}
          >Projects
          </button>
          <button 
            className={ isSelected('more') } 
            onClick={ () => {
              selectedTab==='more' ? setSelectedTab('none') : setSelectedTab('more');
              scrollToContent();
            }}
          >More
          </button>
        </div>
        <div id='content'></div>
        <Content currentTab={selectedTab}/>
      </div>
      <footer className='App-footer'>
        <p className='footer'>* This website was completely hand coded and published with the help of Vercel</p>
      </footer>
    </div>
  );
}

function Content({currentTab}) {
  let content = <div></div>;

  switch (currentTab) {
    case 'resume':
      content=<div><Resume/></div>;
      break;
    case 'projects':
      content=<SnakeGame/>;
      break;
    case 'more':
      content=(
      <div>Works In Progress...
        <div><PolyRhythm/></div>
      </div>
      );
      break;
    default:
      content=<div></div>;
      break;
  }

  return content;
}

export default App;