import React from 'react';

function ListItems(props) {
    const items = props.items.map((item, index) => ({ index, item }));
    
    const listItems = items.map(({index, item}) => <li key={index} >{item}</li>);
    return ( 
        <ul>{listItems}</ul>
    );
  }

  export default ListItems;