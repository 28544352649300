import './PolyRhythm.css';

function PolyRhythm () {
  let unicode='\u26F5\uFE0E';
  return (
    <div className='unicode'>
      〔~~~{unicode}~~~〕
    </div>
  );
}

export default PolyRhythm;