import React, { Component } from 'react';
import Snake from '../Snake';
import Food from '../Food';

import './SnakeGame.css';

const getRandomFood = () => {
  let min = 1;
  let max = 98;
  let x = Math.floor((Math.random()*(max - min + 1) + min) / 2) * 2;
  let y = Math.floor((Math.random()*(max - min + 1) + min) / 2) * 2;
  return [x, y];
};

const initialState = {
  food: getRandomFood(),
  direction: 'RIGHT',
  bufferedDirection: null,
  oldDirection: null,
  speed: 1,
  route: 'menu',
  snakeDots: [[0,0], [0,2], [0,4], [0,6]],
  score: 0,
  highScore: 0
};

class SnakeGame extends Component {
  constructor() {
    super();
    this.state = initialState;
    this.allPossibleCoordinates = this.getAllCoordinates();
  }

  getAllCoordinates() {
    let coordinates = [];
    var i, j;
    for(i=0; i<50; i++) {
      for(j=0; j<50; j++) { 
        coordinates.push([i*2, j*2]); 
      }
    }
    return coordinates;
  }

  // getAvailableCoordinates() {
  //   let coordinates = [...this.allPossibleCoordinates];
  //   let dots = [...this.state.snakeDots];
  //   dots.forEach(dot => {
  //     for (var i = 0; i < coordinates.length; i++) {
  //       if (coordinates[i][0] === dot[0] && coordinates[i][1] === dot[1]) {
  //           coordinates.splice(i, 1);
  //       }
  //     }
  //     return coordinates;
  //   });
  // }

  shuffle(array) {
    let currentIndex = array.length,  randomIndex;
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  getFirstAvailable(coordinates) {
    for (let i=0; i<coordinates.length; i++) {
      let available = true;
      for(let j=0; j<this.state.snakeDots.length; j++) {
        let dot = this.state.snakeDots[j];
        if (coordinates[i][0] === dot[0] && coordinates[i][1] === dot[1]) {
          available = false;
        }
      }
      if (available===false) {
        console.log('Food placed in bad coordinate: '+coordinates[i]);
      } else {
        return coordinates[i];
      }
    }
  }

  componentDidMount() {
    setInterval(this.moveSnake, 100/this.state.speed);
    document.onkeydown = this.onKeyDown;
  }

  componentDidUpdate() {
    this.onSnakeOutOfBounds();
    this.onSnakeCollapsed();
    this.onSnakeEats();
  }

  reverseDirection(direction) {
    switch (direction) {
      case 'RIGHT' : return 'LEFT'
      case 'LEFT' : return 'RIGHT'
      case 'UP' : return 'DOWN'
      case 'DOWN' : return 'UP'
      default : return;
    }
  }

  goLeft = () => (this.state.oldDirection === 'RIGHT') ? this.setState({ bufferedDirection: 'LEFT' }) : this.setState({ direction: 'LEFT' });
  goUp = () => (this.state.oldDirection === 'DOWN') ? this.setState({ bufferedDirection: 'UP' }) : this.setState({ direction: 'UP' });
  goRight = () => (this.state.oldDirection === 'LEFT') ? this.setState({ bufferedDirection: 'RIGHT' }) : this.setState({ direction: 'RIGHT' });
  goDown =  () => (this.state.oldDirection === 'UP') ? this.setState({ bufferedDirection: 'DOWN' }) : this.setState({ direction: 'DOWN' });

  onKeyDown = e => {
    e.preventDefault();
    switch (e.keyCode) {
      case 37:  // left arrow
        this.goLeft();
        break;
      case 38:  // up arrow
        this.goUp();
        break;
      case 39:  // right arrow
        this.goRight();
        break;
      case 40:  // down arrow
        this.goDown();
        break;
      default :
        break;
    }
  };

  moveSnake = () => {
    let dots = [...this.state.snakeDots];
    let head = dots[dots.length - 1];
    if (this.state.route === 'game') {
      switch (this.state.direction) {
        case 'RIGHT':
          head = [head[0] + 2, head[1]];
          break;
        case 'LEFT':
          head = [head[0] - 2, head[1]];
          break;
        case 'DOWN':
          head = [head[0], head[1] + 2];
          break;
        case 'UP':
          head = [head[0], head[1] - 2];
          break;
        default : 
          break;
      }
      dots.push(head);
      dots.shift();
      this.setState({ snakeDots: dots, oldDirection: this.state.direction });

      if (this.state.bufferedDirection !== null) {
        let buf = this.state.bufferedDirection;
        (this.state.bufferedDirection !== this.reverseDirection(this.state.oldDirection)) ?
          this.setState({ direction: buf, bufferedDirection: null }) : this.setState({ bufferedDirection: null });
      } 
    }
  };

  onSnakeOutOfBounds() {
    let head = this.state.snakeDots[this.state.snakeDots.length - 1];
    if (this.state.route === 'game') {
      if (head[0] >= 100 || head[1] >= 100 || head[0] < 0 || head[1] < 0) {
        this.gameOver();
      }
    }
  }

  onSnakeCollapsed() {
    let snake = [...this.state.snakeDots];
    let head = snake[snake.length - 1];
    snake.pop();
    snake.forEach(dot => {
      if (head[0] === dot[0] && head[1] === dot[1]) {
        this.gameOver();
      }
    });
  }

  onSnakeEats() {
    let head = this.state.snakeDots[this.state.snakeDots.length - 1];
    let food = this.state.food;
    
    if (head[0] === food[0] && head[1] === food[1]) {
      this.setState({ food: this.getFirstAvailable(this.shuffle([...this.allPossibleCoordinates])) });
      this.increaseSnake();
      this.increaseSpeed();
      this.increaseScore();
    }
  }

  increaseScore() { this.setState({ score: this.state.score + 1 }); }

  increaseSnake() {
    let newSnake = [...this.state.snakeDots];
    newSnake.unshift([],[],[],[]);
    this.setState({ snakeDots: newSnake });
  }

  increaseSpeed() {
    if (this.state.speed < 2) {
      this.setState({ speed: this.state.speed + 0.1 });
    }
  }

  newGame = () => { this.setState({ score: 0, route: 'game' }); }
  gameOver = () => {  
    let score = this.state.score;
    let highScore = this.state.highScore;
    this.setState(initialState); 
    this.setState({ score: score });
    (score > highScore) ? this.setState({ highScore: score }) : this.setState({highScore: highScore});
  }

  render() {
    const { route, snakeDots, food, direction, score, highScore } = this.state;
    let style = { padding: '10px'}
    return (
      <div className='snake-game'>
        <h2><code>Snake in ReactJS</code></h2>
        <div className='scoreboard'>
          <h4 className='score-title'>Score: </h4><h4 className='score-value'>{score}</h4>
          <h4 className='score-title'> High Score: </h4><h4 className='score-value'>{highScore}</h4>
        </div>
        { 
          (route === 'menu') ? (
            <div className='game-area' style={style}>
              <div>
                <button onClick={this.newGame} className='button start'>START GAME</button>
              </div>
            </div>
          ) : (
            <div className='game-area' style={style}>
              <Snake direction={direction} snakeDots={snakeDots}/>
              <Food dot={food}/>
            </div>
          )
        }
        <div className='gamepad'>
          <div className='left-button'>
            <button className="gamepad-button" type="button" onTouchStart={this.goLeft} onMouseDown={this.goLeft}>
              <div className="button-top"><span className='turn-180'>&#10140;</span></div>
              <div className="button-bottom"></div>
              <div className="button-base"></div>
            </button>
          </div>
          <div>
            <div className='up-button'>
              <button className="gamepad-button" type="button" onTouchStart={this.goUp} onMouseDown={this.goUp}>
                <div className="button-top"><span className='turn-270'>&#10140;</span></div>
                <div className="button-bottom"></div>
                <div className="button-base"></div>
              </button>
            </div>
            <div className='down-button'>
              <button className="gamepad-button" type="button" onTouchStart={this.goDown} onMouseDown={this.goDown}>
                <div className="button-top"><span className='turn-90'>&#10140;</span></div>
                <div className="button-bottom"></div>
                <div className="button-base"></div>
              </button>
            </div>
          </div>

          <div className='right-button'>
            <button className="gamepad-button" type="button" onTouchStart={this.goRight} onMouseDown={this.goRight}>
              <div className="button-top"><span>&#10140;</span></div>
              <div className="button-bottom"></div>
              <div className="button-base"></div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SnakeGame;
